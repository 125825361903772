import React from 'react'
import WhatWeDo from '../body/whatwedo'
import Footer from '../footer'

export default function Services() {
  return (
    <div>
    
      {/* <Footer/> */}
    </div>
  )
}
